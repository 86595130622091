.modalContent {
  overflow-x: hidden; /* Ensure no overflow in x-direction */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.customModal {
  max-width: 1000px; /* Adjust the width as needed */
  width: 90% !important;
  max-height: 100%;
  display: flex;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h2 {
  color: #007e8d;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: #007e8d;
  cursor: pointer;
}

.lineAfter {
  color: #007e8d;
  font-weight: 300;
  margin: 0;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
}

.lineAfter:after {
  content: "";
  display: block;
  margin: 15px 0;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.modalBody {
  padding: 1rem 0;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.fileActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.uploadButton {
  background-color: #007e8d;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}

.uploadButton:hover {
  background-color: #64afcf;
}

.downloadButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.downloadButton {
  background-color: #007e8d;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}

.downloadButton:hover {
  background-color: #64afcf;
}

.uploadForm {
  text-align: left;
}

.uploadInstructions {
  margin-bottom: 20px;
}

.fileNamingGuidelines {
  text-align: left;
  margin: 0;
}

.uploadBox {
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px; /* Ensure a fixed height to vertically center the text */
}

.backButton {
  background-color: #007e8d;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  width: 100%; /* Span the width of the file upload box */
}

.pass_loader{
  height: 366px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #007e8d;
}