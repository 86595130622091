.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  flex: 1;
  padding-top: 20px;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
}

.paragraph {
  margin-bottom: 0px;
  font-weight: bold;
  padding-left: 10px;
}

.custom_button {
  margin: 15px 5px;
  border-radius: 5px;
  width: 80px;
  display: flex !important;
  align-self: flex-end;
  justify-self: flex-end;
}

.clear_button {
  margin: 15px 5px;
  border-radius: 5px;
  width: 80px;
  display: flex !important;
}

.flex_container {
  display: flex;
  align-items: center;
}

.clearButton {
    font-family: "Segoe UI";
    background-color: transparent;
    color: #007e8d;
    border: none;
    outline: none;
    font-size: 14px !important;
    font-weight: 300;
}

.clearButton:hover {
  color: #6b6b6be8;
}

.borderRight {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-right: 1px solid #cccccc;
  /* margin: 25px; */
  /* height: 400px; */
}

.fileSetup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
}

hr {
  width: 100%;
}

ul {
    padding: 0px;
}

.column > h3 {
    margin: 0px;
}

.instructions {
display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
  max-width: 500px;
}

.uploadRow {
    display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}

.innerColumnContainer {
  display: flex;
  flex-wrap: wrap;
}

.innerColumn {
  flex: 1;
  padding: 10px;
}

@media (max-width: 830px) {
  .borderRight {
      display: none;
  }
}

.loadingContainer {
  text-align: center;
}

.loadingContainer:first-child {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}

.column .columnLocation {
  width: 100%;
}

.columnImmunizationDate label{
  text-wrap: nowrap;
}

.error{
  margin-top: 20px;
  color: #cc0000;
}

.customContainer {
  width: 100%;
  min-width: 900px;
}

.column1{
  width: 60%;
}
.column3{
  max-width: 30%;
}

