.container {
    max-width: 320px;
    min-width: 200px;
    margin: auto;
    position: relative;

    ul {
        padding: 0px;
        margin: 0px;
    }
}

.selectBtn {
    padding: 8px;
    width: 100%;
    border: 1px solid #CCC;
    appearance: none;
    background-image: url("../../images/select.svg");
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    padding-right: 40px;
    cursor: pointer;
}

.selectBtn:hover {
    border: 1px solid #000;
    color: #007e8d;
}

.listItems {
    position: absolute;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid #ccc;
    margin-top: 5px;
    padding: 0px;
    display: none;
    z-index: 10;
    width: fit-content;
}

.selectBtn.open+.listItems {
    display: block;
}

.item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    /* width: fit-content; */

    label {
        display: flex;
        align-items: center;
        margin: 0px;
        cursor: pointer;
        color: #007e8d;

        input {
            margin-right: 10px;
            width: 18px;
            height: 18px;
        }
    }

    label:hover {
        color: #fff;
    }

    li {
        cursor: pointer;
    }
}

.item:hover {
    background-color: #818181;

    label {
        color: #fff;
    }
}

.checkbox {
    margin-right: 10px;
}

.subItems {
    position: absolute;
    left: 100%;
    top: 0;
    background: white;
    border: 1px solid #ccc;
    display: none;
    min-width: 180px;
    width: 100%;
    /* max-width: 350px; */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    width: fit-content;
}

.item:hover .subItems {
    display: block;
}

.subItem {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    white-space: nowrap;

    li {
        cursor: pointer;
    }

    label {
        margin: 0px;
        cursor: pointer;
        color: #007e8d !important;
    }
}

.subItem:hover {
    background-color: #818181;

    label {
        color: #fff !important;
    }
}

.tipContainer {
    margin-top: 5px;
}

.customTip {
    max-width: 300px;
    padding: 5px;
    font-size: 0.65rem !important;
    animation: animateTool 0.5s;
}